<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 15px"
        @click="onClickLeft"
      />
      <span style="font-size: 20px; color: #fff; font-weight: bold"
        >查看详情</span
      >
    </div>
    <div class="user_info">
      <div
        class="left"
        :style="{
          background:
            patientinfo.nursingClassName == '特级护理'
              ? '#FE5656'
              : patientinfo.nursingClassName == '一级护理'
              ? '#A18AEA'
              : patientinfo.nursingClassName == '二级护理'
              ? '#47ABFF'
              : patientinfo.nursingClassName == '三级护理'
              ? '#16B78C'
              : '#16B78C',
        }"
      >
        {{ patientinfo.bedNo }}
      </div>
      <div class="right">
        <div>
          <span class="name">{{ patientinfo.name }}</span
          ><span class="sex"
            >{{ patientinfo.sex }} {{ patientinfo.age }}岁
            <van-icon
              name="clock-o"
              style="margin-left: 5px"
              @click="checkTime"
            />
          </span>
          <van-icon name="arrow-down" @click="goPatient" />
        </div>
        <div class="num">{{ patientinfo.patientId }}</div>
      </div>
    </div>
    <div class="content">
      <van-sidebar v-model="activeKey" @change="onChange">
        <van-sidebar-item
          :title="item.name"
          v-for="(item, i) in listType"
          :key="i"
        />
      </van-sidebar>

      <div
        class="time_info"
        v-if="
          medicalRecordTypeName != '检验报告' &&
          medicalRecordTypeName != '检查报告' &&
          medicalRecordTypeName != '长期医嘱' &&
          medicalRecordTypeName != '门诊病历' &&
          medicalRecordTypeName != '急诊病历' &&
          medicalRecordTypeName != '体温单'
        "
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="medicalRecordList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in medicalRecordList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.modifyTime }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 10px;
                  margin-bottom: 15px;
                "
                @click="onClickInfo(item, 0, 4)"
              >
                {{
                  medicalRecordTypeName == "血压报告"
                    ? item.creatorName
                    : item.docTitle
                }}
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '检验报告'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="inspectionList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in inspectionList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.resultsRptDateTime }}
            </div>
            <div :class="[item.resultStatus == 4 ? 'activeStatus' : 'status']">
              {{ item.resultStatus == 4 ? "已出" : "未出" }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
                @click="onClickInfo(item, 1, item.resultStatus, item.testNo)"
              >
                {{ item.subject }}
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '检查报告'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="checkList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in checkList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.reportDateTime }}
            </div>
            <div
              :class="[item.resultStatusCode == 4 ? 'activeStatus' : 'status']"
            >
              {{ item.resultStatusCode == 4 ? "已出" : "未出" }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
                @click="
                  onClickInfo(item, 2, item.resultStatusCode, item.examNo)
                "
              >
                {{ item.examItems }}
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '门诊病历'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="outpatientMedicalRecordsList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in outpatientMedicalRecordsList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.visitDate }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
                @click="onClickInfo(item, 3, 'null')"
              >
                {{ item.diagDesc }}
                <div style="lineheight: 30px">
                  <span> {{ item.deptName }}</span>
                  <span style="padding-left: 10px">{{ item.doctor }}</span>
                </div>
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '急诊病历'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="medicalRecordsRespList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in medicalRecordsRespList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.createDate }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
                @click="onClickInfo(item, 5, 4)"
              >
                {{ item.mrfileName }}
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '体温单'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="vitalSignsList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in vitalSignsList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.recordingDate }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 0px;
                "
                @click="onClickInfo(item, 3, 'null')"
              >
                {{ item.vitalSigns }}
                <div style="lineheight: 30px">
                  {{ item.vitalSignsValues + item.units }}
                </div>
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>

      <div
        class="time_info time_infoBox"
        v-if="medicalRecordTypeName == '长期医嘱'"
      >
        <van-loading type="spinner" v-show="loading" />
        <van-steps
          direction="vertical"
          :active="prescriptionList.length"
          active-color="#1578F9"
        >
          <van-step v-for="(item, i) in prescriptionList" :key="i">
            <div style="color: #999; font-size: 15px">
              {{ item.startDateTime + "~" + item.enterDateTime }}
            </div>
            <van-badge :dot="false">
              <div
                style="
                  color: #333;
                  font-weight: bold;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
                @click="onClickInfo(item, 3, 4)"
              >
                {{ item.orderText }}
              </div>
            </van-badge>
          </van-step>
        </van-steps>
        <van-empty image="search" description="暂无数据" v-show="showEmpty" />
      </div>
    </div>
    <van-popup v-model="show" id="infoshow">
      <ueserInfo :patientinfo="patientinfo"></ueserInfo>
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        value-key="admissionDate"
        :columns="timeList"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { setStore, getStore } from "@/utils/localStorage.js";
import ueserInfo from "@/views/patients/detail.vue";
import {
  getRecordListType,
  getDigitalMedicalRecordList,
  getPatientInfo,
  getPatientHospitalizationHistory,
  getInspectionList,
  getCheckList,
  getOutpatientMedicalRecords,
  getJZMedicalRecordsResp,
  getVitalSigns,
} from "@/api/patients";
import { doctorsAdvice } from "@/api/doctorAdvice";
import { Toast } from "vant";
import dateUtil from "@/api/dateUtil.js";
// import {  getStore } from "@/utils/localStorage.js";
export default {
  data() {
    return {
      showEmpty: false,
      loading: false,
      show: false,
      showPicker: false,
      activeKey: 0,
      medicalRecordTypeName: "",
      value: "",
      timeList: [],
      visitId: "",
      deptCode: "",
      patientId: "",
      // tenantId: getStore({name:"tenantId"}),
      patientinfo: {},
      listType: [],
      medicalRecordList: [],
      inspectionList: [],
      checkList: {},
      prescriptionList: [],
      outpatientMedicalRecordsList: [],
      medicalRecordsRespList: [],
      vitalSignsList: [],
      // signWord: sha1(getStore({ name: "loginData"})).toUpperCase(),
    };
  },
  components: { ueserInfo },

  created() {
    if (this.$route.query.patientId) {
      this.patientinfo.patientId = this.$route.query.patientId;
    }
    if (this.$route.query.visitId) {
      this.patientinfo.visitId = this.$route.query.visitId;
    }
    // if (this.$route.query.activeKey) {
    //   if (this.medicalRecordTypeName == "检验报告") {
    //     this.getInspectionListInfo();
    //   } else if (this.medicalRecordTypeName == "检查报告") {
    //     this.getCheckListInfo();
    //   } else if (this.medicalRecordTypeName == "长期医嘱") {
    //     this.getDoctorsAdvice();
    //   } else if (this.medicalRecordTypeName == "门诊病历") {
    //     this.getOutpatientMedicalRecordsList();
    //   } else if (this.medicalRecordTypeName == "急诊病历") {
    //     this.getJZMedicalRecordsRespList();
    //   } else if (this.medicalRecordTypeName == "体温单") {
    //     this.getVitalSignsList();
    //   }
    // }
  },

  mounted() {
    this.showEmpty = false;
    document.getElementById("container").style.background = "#F6F6F6";
    this.patientId = this.$route.query.patientId;
    this.visitId = this.$route.query.visitId;
    this.deptCode = this.$route.query.deptCode;
    // this.activeKey = this.$route.query.activeKey;
    this.getRecordList();
    this.getInfo();
    this.getTime();
  },
  methods: {
    // 获取类型-侧边搜索栏
    getRecordList() {
      getRecordListType(
        this.tenantId
        // 'ZHOUDF',
        // '40BD001563085FC35165329EA1FF5C5ECBDBBEEF',
        // localStorage.getItem('petName'),
        // this.signWord,
      ).then((res) => {
        if (res.data.code == "200") {
          this.listType = res.data.data;
          this.listType.map((item, index) => {
            if (item.name == "检验报告") {
              item.tag = sessionStorage.getItem("one");
            }
            if (item.name == "病程记录") {
              item.tag = sessionStorage.getItem("two");
            }
            if (item.name == "长期医嘱") {
              item.tag = sessionStorage.getItem("three");
            }
            if (item.name == "体温单") {
              item.tag = sessionStorage.getItem("four");
            }
            if (item.name == "手术记录") {
              item.tag = sessionStorage.getItem("five");
            }
            console.log(item.name, "item");
          });

          if (this.$route.query.medicalRecordTypes) {
            this.medicalRecordTypeName = this.$route.query.medicalRecordTypes;
          } else {
            this.medicalRecordTypeName = this.listType[0].name;
          }
          this.listType.map((item, index) => {
            // 判断是否相等 载入的时候选中侧边搜索栏
            if (item.name == this.medicalRecordTypeName) {
              this.activeKey = index;
            }
          });
          console.log(this.listType, " this.listType");
          if (this.medicalRecordTypeName == "检验报告") {
            this.medicalRecordList = [];
            this.checkList = [];
            this.prescriptionList = [];
            this.outpatientMedicalRecordsList = [];
            this.medicalRecordsRespList = [];
            this.vitalSignsList = [];
            this.getInspectionListInfo();
          } else if (this.medicalRecordTypeName == "检查报告") {
            this.medicalRecordList = [];
            this.inspectionList = [];
            this.prescriptionList = [];
            this.outpatientMedicalRecordsList = [];
            this.medicalRecordsRespList = [];
            this.vitalSignsList = [];
            this.getCheckListInfo();
          } else if (this.medicalRecordTypeName == "长期医嘱") {
            this.medicalRecordList = [];
            this.inspectionList = [];
            this.checkList = [];
            this.outpatientMedicalRecordsList = [];
            this.medicalRecordsRespList = [];
            this.vitalSignsList = [];
            this.getDoctorsAdvice();
          } else if (this.medicalRecordTypeName == "门诊病历") {
            this.medicalRecordList = [];
            this.inspectionList = [];
            this.checkList = [];
            this.prescriptionList = [];
            this.medicalRecordsRespList = [];
            this.vitalSignsList = [];

            this.getOutpatientMedicalRecordsList();
          } else if (this.medicalRecordTypeName == "急诊病历") {
            this.medicalRecordList = [];
            this.inspectionList = [];
            this.checkList = [];
            this.prescriptionList = [];
            this.outpatientMedicalRecordsList = [];

            this.vitalSignsList = [];
            this.getJZMedicalRecordsRespList();
          } else if (this.medicalRecordTypeName == "体温单") {
            this.medicalRecordList = [];
            this.inspectionList = [];
            this.checkList = [];
            this.prescriptionList = [];
            this.outpatientMedicalRecordsList = [];
            this.medicalRecordsRespList = [];

            this.getVitalSignsList();
          } else {
            this.inspectionList = [];
            this.checkList = [];
            this.prescriptionList = [];
            this.outpatientMedicalRecordsList = [];
            this.medicalRecordsRespList = [];
            this.vitalSignsList = [];
            this.getMedicalRecordList();
          }
          // this.getMedicalRecordList();
        }
      });
    },
    //检验报告
    getInspectionListInfo(visitNo) {
      this.loading = true;
      this.showEmpty = false;
      getInspectionList(this.patientId, visitNo)
        .then((res) => {
          if (res.data.code == "200") {
            this.loading = false;
            this.inspectionList = res.data.data || [];
            console.log(this.inspectionList, "this.inspectionList检验");
            console.log(this.inspectionList, "this.inspectionList");
            if (this.inspectionList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
              this.showEmpty = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
    //检查报告
    getCheckListInfo(visitNo) {
      this.loading = true;
      this.showEmpty = false;
      getCheckList(this.patientId, visitNo)
        .then((res) => {
          if (res.data.code == "200") {
            this.loading = false;
            this.checkList = res.data.data || [];
            if (this.checkList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
               this.showEmpty = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },

    //长期医嘱
    getDoctorsAdvice() {
      this.loading = true;
      this.showEmpty = false;
      let row = {};
      row.patientId = this.patientinfo.patientId;
      row.visitId = this.patientinfo.visitId;
      row.repeatIndicator = 1;
      doctorsAdvice(row)
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            console.log(res, "res医嘱");
            this.prescriptionList = res.data.data.resps || [];
            this.prescriptionList.map((item) => {
              if (item.startDateTime) {
                item.startDateTime = dateUtil.formatDate3(item.startDateTime);
              }
              if (item.enterDateTime) {
                item.enterDateTime = dateUtil.formatDate3(item.enterDateTime);
              }
            });
            if (this.prescriptionList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
              this.showEmpty = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
    //获取门诊病历
    getOutpatientMedicalRecordsList() {
      this.loading = true;
      this.showEmpty = false;
      getOutpatientMedicalRecords(this.patientinfo.patientId)
        .then((res) => {
          console.log(res, "门诊病历");
          if (res.data.code == 200) {
            this.loading = false;
            this.outpatientMedicalRecordsList = res.data.data || [];

            if (this.outpatientMedicalRecordsList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
               this.showEmpty = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
    //获取急诊病历
    getJZMedicalRecordsRespList() {
      this.loading = true;
      this.showEmpty = false;
      getJZMedicalRecordsResp(this.patientinfo.patientId)
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.medicalRecordsRespList = res.data.data || [];
            if (this.medicalRecordsRespList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
               this.showEmpty = false;
            }
           
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
    //获取体温单
    getVitalSignsList() {
      this.loading = true;
      this.showEmpty = false;
      getVitalSigns(this.patientinfo.patientId, this.patientinfo.visitId)
        .then((res) => {
          if (res.data.code == 200) {
            this.loading = false;
            this.vitalSignsList = res.data.data || [];
            if (this.vitalSignsList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
               this.showEmpty = false;
            }
           
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },

    // 获取清单
    getMedicalRecordList() {
      this.loading = true;
      this.showEmpty = false;
      this.medicalRecordList = [];
      getDigitalMedicalRecordList(
        this.medicalRecordTypeName,
        this.patientId,
        this.tenantId,
        this.visitId
      )
        .then((res) => {
          if (res.data.code == "200") {
            console.log(res, "清单");
            this.loading = false;
            this.medicalRecordList = res.data.data || [];

            if (this.medicalRecordList.length == 0) {
              this.loading = false;
              this.showEmpty = true;
            }else{
               this.showEmpty = false;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.showEmpty = true;
        });
    },
    // 获取详细
    getInfo() {
      getPatientInfo(
        this.deptCode,
        this.patientId,
        this.tenantId
        // localStorage.getItem('petName'),
        // this.signWord,
      ).then((res) => {
        if (res.data.code == "200") {
          this.patientinfo = res.data.data;
        }
      });
    },
    // 获取详细
    getTime() {
      getPatientHospitalizationHistory(this.patientId, this.tenantId).then(
        (res) => {
          if (res.data.code == "200") {
            this.timeList = res.data.data;
          }
        }
      );
    },
    // 获取时间
    checkTime() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.showEmpty = false;
      this.value = value.admissionDate;
      this.visitId = value.visitId;
      this.showPicker = false;
      console.log('this.visitId',this.visitId)
      this.getMedicalRecordList();
      if (this.medicalRecordTypeName == "检验报告") {
        this.getInspectionListInfo(this.visitId);
      }
      if (this.medicalRecordTypeName == "检查报告") {
        this.getCheckListInfo(this.visitId);
      }
    },
    onChange(index) {
      this.showEmpty = false;
      this.loading = false;
      this.activeKey = index;
      this.medicalRecordTypeName = this.listType[index].name;
      this.listType[index].tag = "no";

      // if (this.medicalRecordTypeName == "病程记录") {
      //   sessionStorage.setItem("two", "no");
      // }
      // if (this.medicalRecordTypeName == "手术记录") {
      //   sessionStorage.setItem("five", "no");
      // }
      if (this.medicalRecordTypeName == "检验报告") {
        // sessionStorage.setItem("one", "no");
        this.medicalRecordList = [];
        this.checkList = [];
        this.prescriptionList = [];
        this.outpatientMedicalRecordsList = [];
        this.medicalRecordsRespList = [];
        this.vitalSignsList = [];
        this.getInspectionListInfo();
      } else if (this.medicalRecordTypeName == "检查报告") {
        this.medicalRecordList = [];
        this.inspectionList = [];
        this.prescriptionList = [];
        this.outpatientMedicalRecordsList = [];
        this.medicalRecordsRespList = [];
        this.vitalSignsList = [];
        this.getCheckListInfo();
      } else if (this.medicalRecordTypeName == "长期医嘱") {
        // sessionStorage.setItem("three", "no");
        this.medicalRecordList = [];
        this.inspectionList = [];
        this.checkList = [];
        this.outpatientMedicalRecordsList = [];
        this.medicalRecordsRespList = [];
        this.vitalSignsList = [];
        this.getDoctorsAdvice();
      } else if (this.medicalRecordTypeName == "门诊病历") {
        this.medicalRecordList = [];
        this.inspectionList = [];
        this.checkList = [];
        this.prescriptionList = [];
        this.medicalRecordsRespList = [];
        this.vitalSignsList = [];

        this.getOutpatientMedicalRecordsList();
      } else if (this.medicalRecordTypeName == "急诊病历") {
        this.medicalRecordList = [];
        this.inspectionList = [];
        this.checkList = [];
        this.prescriptionList = [];
        this.outpatientMedicalRecordsList = [];

        this.vitalSignsList = [];
        this.getJZMedicalRecordsRespList();
      } else if (this.medicalRecordTypeName == "体温单") {
        // sessionStorage.setItem("four", "no");
        this.medicalRecordList = [];
        this.inspectionList = [];
        this.checkList = [];
        this.prescriptionList = [];
        this.outpatientMedicalRecordsList = [];
        this.medicalRecordsRespList = [];

        this.getVitalSignsList();
      } else {
        this.inspectionList = [];
        this.checkList = [];
        this.prescriptionList = [];
        this.outpatientMedicalRecordsList = [];
        this.medicalRecordsRespList = [];
        this.vitalSignsList = [];
        this.getMedicalRecordList();
      }

      // this.listType.map((item, index) => {
      //   if (this.medicalRecordTypeName == "检验报告") {
      //     item.tag = sessionStorage.setItem("one", "no");
      //   }
      //   if (this.medicalRecordTypeName == "病程记录") {
      //     item.tag = sessionStorage.setItem("two", "no");
      //   }
      //   if (this.medicalRecordTypeName == "长期医嘱") {
      //     item.tag = sessionStorage.setItem("three", "no");
      //   }
      //   if (this.medicalRecordTypeName == "体温单") {
      //     item.tag = sessionStorage.setItem("four", "no");
      //   }
      //   if (this.medicalRecordTypeName == "手术记录") {
      //     item.tag = sessionStorage.setItem("five", "no");
      //   }
      // });
      //   this.medicalRecordTypeName == "入院纪录" ||
      //   this.medicalRecordTypeName == "病程纪录" ||
      //   this.medicalRecordTypeName == "出院纪录" ||
      //   this.medicalRecordTypeName == "死亡纪录" ||
      //   this.medicalRecordTypeName == "知情同意书" ||
      //   this.medicalRecordTypeName == "手术纪录"
      // ) {

      // }
    },
    goPatient() {
      // this.$router.push({ path: '/patientinfo' })
      this.show = true;
    },
    onClickLeft() {
      // this.$router.back();
      this.$router.push({
        path: "/patients",
        query: { deptCode: this.$route.query.deptCode },
      });
    },
    onClickInfo(item, type, status, testNo) {
      if (status == "null") {
        return;
      }
      if (status == 4) {
        this.$router.push({
          path: "/jcInfo",
          query: {
            patientId: this.patientId,
            docId: item.docId,
            visitId: this.visitId,
            visitType: item.visitType,
            medicalRecordTypes: this.medicalRecordTypeName,
            url: item.modifierName,
            activeKey: this.activeKey,
            deptCode: this.deptCode,
            type: type,
            testNo: testNo,
            reporter: item.reporter,
            resultsRptDateTime: item.resultsRptDateTime,
            subject: item.subject,
            examItems: item.examItems,
            reportDateTime: item.reportDateTime,
            orderNo: item.orderNo,
            orderSubNo: item.orderSubNo,
            htmlFilePath: item.htmlFilePath,
          },
        });
      } else {
        Toast.fail("报告未出");
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-loading {
  left: 45%;
  top: 10px;
}
/deep/ .van-steps {
  min-height: 80px;
}
.wrap {
  background: #f6f6f6;
  // height: 100vh;
  // margin-bottom: -50px;
  height: 100%;

  .nav_bar {
    color: #fff;
    background-color: #1578f9;
    padding: 10px 0px 50px 0px;
    text-align: center;
  }

  .user_info {
    width: 342px;
    // height: 81px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 0px rgba(7, 2, 14, 0.06);
    border-radius: 10px;
    margin: -30px auto 0;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;

    .left {
      width: 43px;
      height: 43px;
      background: #fe5656;
      border-radius: 10px;
      font-size: 21px;
      line-height: 43px;
      text-align: center;
      color: #ffffff;
      margin-right: 20px;
    }

    .right {
      margin-top: -5px;

      .name {
        font-weight: bold;
        color: #333333;
        font-size: 19px;
        margin-right: 20px;
      }

      .sex {
        font-size: 15px;
        // font-weight: bold;
        color: #999999;
        margin-top: 5px;
      }

      .num {
        font-size: 16px;
        // font-weight: bold;
        color: #999999;
        margin-top: 5px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin: 17px;

    .time_info {
      // width: 217px;
      width: calc(100% - 96px);
      height: 100%;
      background: #ffffff;
      // border-radius: 10px;
    }
  }
}

/deep/.van-sidebar {
  width: 96px;
  // width: 140px;
}

/deep/.van-sidebar-item {
  padding: 10px 12px;
  // border-radius: 10px 0 0 10px;
  text-align: center;
  background-color: #f6f6f6;
}

.van-sidebar-item--select {
  background-color: #fff;
}

/deep/.van-sidebar-item--select::before {
  background-color: #1578f9 !important;
  height: 42px !important;
  width: 4px !important;
}

/deep/.van-step__icon--active {
  color: #1578f9;
}
/deep/ .van-step--vertical {
  padding: 10px 30px 0 0;
}

/deep/.van-badge--fixed {
  top: 15px;
  right: -4px;
}

/deep/.van-icon-arrow-down {
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #999;
}

// /deep/.van-popup {
//     max-height: 70% !important;
//     border-radius: 10px !important;
//     width: 90% !important;
// }
#infoshow {
  max-height: 70% !important;
  border-radius: 10px !important;
  width: 90% !important;
}
.time_infoBox {
  position: relative;
}
.status {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 10px;
  color: #999999;
}
.activeStatus {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 10px;
  color: #1578f9;
}
</style>
