import request from '@/router/axios';

// 患者列表列表 current,size,
export const getPatientInfos = (deptCode,patientName,passWord,tenantId,userName) => request({
  url: '/api/blade-remote/hospitaldata/getPatientInfos',
  method: 'get',
  params:{
    // current:current,
    // size:size,
    deptCode:deptCode,
    patientName:patientName,
   // passWord:passWord,
    // tenantId:tenantId,
    // userName:userName,
  }
})
// 获取住院科室
export const getDeptList = () => request({
  url: '/api/blade-remote/hospitaldata/getdeptlist',
  method: 'get',
  params:{
    // tenantId:tenantId,
    // userName:userName,
    // passWord:passWord,
  },
  
})
// 获取文档类型
export const getRecordListType = (tenantId) => request({
  url: '/api/blade-remote/hospitaldata/getRecordListType',
  method: 'get',
  params:{
    tenantId:tenantId,
  }
})
// 获取电子病例清单
export const getDigitalMedicalRecordList = (medicalRecordTypeName,patientId,tenantId,visitId) => request({
  url: '/api/blade-remote/hospitaldata/getDigitalMedicalRecordList',
  method: 'get',
  params:{
    medicalRecordTypeName:medicalRecordTypeName,
    patientId:patientId,
    tenantId:tenantId,
    visitId:visitId,
  }
})
// 患者详细
export const getPatientInfo = (deptCode,patientId,tenantId) => request({
  url: '/api/blade-remote/hospitaldata/getPatientInfo',
  method: 'get',
  params:{
    deptCode:deptCode,
    patientId:patientId,
    tenantId:tenantId,
  }
})
//获取电子病例
export const getDigitalMedicalRecordDetail = (docId,patientId,tenantId,visitId,visitType) => request({
  url: '/api/blade-remote/hospitaldata/getDigitalMedicalRecordDetail',
  method: 'get',
  params:{
    docId:docId,
    patientId:patientId,
    tenantId:tenantId,
    visitId:visitId,
    visitType:visitType,
  }
})
//获取入院日期
export const getPatientHospitalizationHistory = (patientId,tenantId) => request({
  url: '/api/blade-remote/hospitaldata/getPatientHospitalizationHistory',
  method: 'get',
  params:{
    patientId:patientId,
    tenantId:tenantId,
  }
})


// 获取检验列表
export const getInspectionList = (patientId,visitNo) => request({
  // url: '/api/blade-handheld-hospital/outpatient/getInspectionList',
  url:"/api/blade-consult/enterprise/wechat/getInspectionList",
  method: 'post',
  data:{
    patientId,
    visitNo
  }
})


// 获取检验详情
export const getInspectionDetail = (testNo) => request({
  url:"/api/blade-consult/enterprise/wechat/getInspectionDetail",
  method: 'post',
  data:{
    testNo,
 
  }
})


// 获取检查列表
export const getCheckList= (patientId,visitNo) => request({
  url:"/api/blade-consult/enterprise/wechat/getCheckList",
  method: 'post',
  data:{
    patientId,
    visitNo,
 
  }
})

// 获取检查详情
export const getCheckDetail= (examNo) => request({
  url:"/api/blade-consult/enterprise/wechat/getCheckDetail",
  method: 'post',
  data:{
    examNo,
 
  }
})

// 获取门诊病历
export const getOutpatientMedicalRecords = (patientId) => request({
  url: '/api/blade-remote/hospitaldata/getOutpatientMedicalRecords',
  method: 'get',
  params:{
    patientId
  }
})

// 获取急诊病历列表
export const getJZMedicalRecordsResp = (patientId) => request({
  url: '/api/blade-remote/hospitaldata/getJZMedicalRecordsResp',
  method: 'get',
  params:{
    patientId
  }
})

// 获取急诊病历详情
export const getMedicalRecordsDetail = (filePath) => request({
  url: '/api/blade-remote/hospitaldata/getMedicalRecordsDetail',
  method: 'get',
  params:{
    filePath
  }
})
// 获取体温单
export const getVitalSigns = (patientId,visitId) => request({
  url: '/api/blade-remote/hospitaldata/getVitalSigns',
  method: 'get',
  params:{
    patientId,
    visitId
  }
})



